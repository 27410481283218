@import url(https://fonts.googleapis.com/css?family=Merriweather);
@import url(https://fonts.googleapis.com/css?family=Merriweather);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100%;
  min-height: 100vh;
  background-image: url(/static/media/Background.9c583b36.jpg);
  background-position: center;
  background-size: cover;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d14 {
  padding: 20px 0;
  text-align: center;
}
.d14 h3 {
  font-family: "Merriweather", serif;
  font-size: 20px;
  letter-spacing: 1px;
  max-width: 320px;
  width: 100%;
  position: relative;
  display: inline-block;
  color: black;
}

.d14 h3 {
  display: table;
  width: auto;
  margin: 15px auto;
  letter-spacing: 2px;
}

.d14 h3:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -25px;
  width: 30px;
  height: 20px;
  border-top: 2px solid #fed57b;
  border-left: 2px solid #fed57b;
}

.d14 h3::after {
  content: "";
  position: absolute;
  bottom: -50%;
  right: -25px;
  width: 30px;
  height: 20px;
  border-bottom: 2px solid #fed57b;
  border-right: 2px solid #fed57b;
}

.blue-container {
  background-color: #cfe8fc;
  padding: 10px;
  border-radius: 5px;
}

.header-buttons {
  width: 60% !important;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .header-buttons {
    width: 90% !important;
  }
}

/*# sourceMappingURL=UtilityPage.css.map */

.calc-container {
  display: block;
  box-sizing: border-box;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.05);
  border-top: 2px dotted rgba(0, 0, 0, 0.05);
}

h4 {
  margin: 0 0 10px 0;
}

.hidden {
  display: none;
}

.fields-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 280px;
  margin: 0 auto;
}

.calculate-button {
  width: 38%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 500px) {
  .fields-container {
    width: 90%;
  }

  .calculate-button {
    width: 45%;
  }
}
.slide-enter {
  opacity: 0;
  transform: scale(0.5);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: all 1000ms;
}

/*# sourceMappingURL=BaseForm.css.map */

.popover-component {
  margin: 0;
  width: 25%;
}

.paper {
  width: 100%;
  margin-top: 10px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background: white;
  box-shadow: 2px 2px 2px grey;
  font-family: "Merriweather", serif;
}

/*# sourceMappingURL=SimplePopper.css.map */

.language-buttons-container {
  width: 80px;
  max-width: 100px;
  display: flex;
  justify-content: space-between;
}

.language-button {
  height: 100%;
  width: 45%;
  outline: none;
  border-radius: 5px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/250px-Flag_of_Ukraine.svg.png");
  background-position: center;
  background-size: cover;
  transition: 0.5s;
  border: 2px solid white;
}
.language-button:last-child {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/250px-Flag_of_Russia.svg.png");
}

.button-active {
  border-color: green;
  box-shadow: 5px 5px 5px darkgrey;
}

/*# sourceMappingURL=LanguageSelector.css.map */

.control-buttons {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  margin: 10px auto;
}

.backward-arrow {
  transform: rotate(180deg) !important;
}

/*# sourceMappingURL=ControlButtons.css.map */

